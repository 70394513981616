/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
.sidecar-banner .sidecar-figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .sidecar-banner .sidecar-figure .figure-picture {
    width: 100%; }
  .sidecar-banner .sidecar-figure figcaption {
    display: block;
    width: 100%; }

.sidecar-banner .sidecar-figure {
  border-radius: 0.25rem;
  overflow: hidden; }
  .sidecar-banner .sidecar-figure .image-link {
    display: contents; }
  .sidecar-banner .sidecar-figure .figure-picture {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }

.sidecar-banner .figure-picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 768.98px) {
    .sidecar-banner .figure-picture.fit-sm-width .image-link {
      width: 100%; }
    .sidecar-banner .figure-picture.fit-sm-width .sidecar-image {
      width: 100%; }
    .sidecar-banner .figure-picture.fit-sm-height .image-link {
      height: 100%; }
    .sidecar-banner .figure-picture.fit-sm-height .sidecar-image {
      height: 100%; } }
  @media (min-width: 769px) and (max-width: 991.98px) {
    .sidecar-banner .figure-picture.fit-md-width .image-link {
      width: 100%; }
    .sidecar-banner .figure-picture.fit-md-width .sidecar-image {
      width: 100%; }
    .sidecar-banner .figure-picture.fit-md-height .image-link {
      height: 100%; }
    .sidecar-banner .figure-picture.fit-md-height .sidecar-image {
      height: 100%; } }
  @media (min-width: 992px) {
    .sidecar-banner .figure-picture.fit-width .image-link {
      width: 100%; }
    .sidecar-banner .figure-picture.fit-width .sidecar-image {
      width: 100%; }
    .sidecar-banner .figure-picture.fit-height .image-link {
      height: 100%; }
    .sidecar-banner .figure-picture.fit-height .sidecar-image {
      height: 100%; } }

.sidecar-banner .sidecar-caption {
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (min-width: 769px) {
    .sidecar-banner .sidecar-caption {
      padding: 2.5rem; } }
  @media screen and (min-width: 320px) and (max-width: 424px) {
    .sidecar-banner .sidecar-caption {
      padding-bottom: 0; } }

.sidecar-banner .caption-image {
  margin-bottom: 1rem; }

.sidecar-banner .caption-text {
  width: 100%; }
  .sidecar-banner .caption-text:last-child h1,
  .sidecar-banner .caption-text:last-child h2,
  .sidecar-banner .caption-text:last-child h3,
  .sidecar-banner .caption-text:last-child h4,
  .sidecar-banner .caption-text:last-child h5,
  .sidecar-banner .caption-text:last-child h6,
  .sidecar-banner .caption-text:last-child p {
    margin-bottom: 0; }

@media (max-width: 768.98px) {
  .sidecar-banner .caption-title h1,
  .sidecar-banner .caption-title h2,
  .sidecar-banner .caption-title h3,
  .sidecar-banner .caption-title h4,
  .sidecar-banner .caption-title h5,
  .sidecar-banner .caption-title h6,
  .sidecar-banner .caption-title p {
    font-size: 18px;
    line-height: 20px; } }

@media screen and (min-width: 320px) and (max-width: 424px) {
  .sidecar-banner .caption-title h1,
  .sidecar-banner .caption-title h2,
  .sidecar-banner .caption-title h3,
  .sidecar-banner .caption-title h4,
  .sidecar-banner .caption-title h5,
  .sidecar-banner .caption-title h6,
  .sidecar-banner .caption-title p {
    font-size: 12px;
    line-height: 20px; } }

.sidecar-banner .caption-desc {
  margin-top: 0.5rem; }
  @media (min-width: 769px) {
    .sidecar-banner .caption-desc {
      margin-top: 1rem; } }
  @media (max-width: 768.98px) {
    .sidecar-banner .caption-desc h1,
    .sidecar-banner .caption-desc h2,
    .sidecar-banner .caption-desc h3,
    .sidecar-banner .caption-desc h4,
    .sidecar-banner .caption-desc h5,
    .sidecar-banner .caption-desc h6,
    .sidecar-banner .caption-desc p {
      font-size: 14px;
      line-height: 19px; } }

.sidecar-banner.horizontal .sidecar-figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  .sidecar-banner.horizontal .sidecar-figure .figure-picture {
    width: 50%; }
    @media screen and (min-width: 320px) and (max-width: 424px) {
      .sidecar-banner.horizontal .sidecar-figure .figure-picture {
        display: block; } }
  .sidecar-banner.horizontal .sidecar-figure figcaption {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%; }

.sidecar-banner.horizontal.slim .figure-picture {
  width: 40%; }

.sidecar-banner.horizontal.slim figcaption {
  width: 60%; }

@media (min-width: 769px) {
  .sidecar-banner.horizontal-tablet .sidecar-figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .sidecar-banner.horizontal-tablet .sidecar-figure .figure-picture {
      width: 50%; } }
    @media screen and (min-width: 769px) and (min-width: 320px) and (max-width: 424px) {
      .sidecar-banner.horizontal-tablet .sidecar-figure .figure-picture {
        display: block; } }

@media (min-width: 769px) {
    .sidecar-banner.horizontal-tablet .sidecar-figure figcaption {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 50%; }
  .sidecar-banner.horizontal-tablet.slim .figure-picture {
    width: 40%; }
  .sidecar-banner.horizontal-tablet.slim figcaption {
    width: 60%; } }

@media (min-width: 769px) {
  .sidecar-banner.vertical-tablet .sidecar-figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .sidecar-banner.vertical-tablet .sidecar-figure .figure-picture {
      width: 100%; }
    .sidecar-banner.vertical-tablet .sidecar-figure figcaption {
      display: block;
      width: 100%; } }

@media (min-width: 992px) {
  .sidecar-banner.horizontal-desktop .sidecar-figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .sidecar-banner.horizontal-desktop .sidecar-figure .figure-picture {
      width: 50%; } }
    @media screen and (min-width: 992px) and (min-width: 320px) and (max-width: 424px) {
      .sidecar-banner.horizontal-desktop .sidecar-figure .figure-picture {
        display: block; } }

@media (min-width: 992px) {
    .sidecar-banner.horizontal-desktop .sidecar-figure figcaption {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 50%; }
  .sidecar-banner.horizontal-desktop.slim .figure-picture {
    width: 40%; }
  .sidecar-banner.horizontal-desktop.slim figcaption {
    width: 60%; } }

@media (min-width: 992px) {
  .sidecar-banner.vertical-desktop .sidecar-figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .sidecar-banner.vertical-desktop .sidecar-figure .figure-picture {
      width: 100%; }
    .sidecar-banner.vertical-desktop .sidecar-figure figcaption {
      display: block;
      width: 100%; } }

@media (min-width: 769px) {
  .slides-lg-2 .sidecar-banner .sidecar-caption,
  .slides-lg-3 .sidecar-banner .sidecar-caption,
  .slides-lg-4 .sidecar-banner .sidecar-caption,
  .slides-lg-5 .sidecar-banner .sidecar-caption {
    padding: 1.5rem; } }

.sidecar-banner.slim figcaption {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.sidecar-banner.slim .sidecar-caption {
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem; }
  @media (min-width: 769px) {
    .sidecar-banner.slim .sidecar-caption {
      padding: 1rem 2.5rem; } }

.sidecar-banner.slim .heading-btns {
  margin-top: 0; }
